.header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
    /* padding: 2rem 6rem; */
    /* margin-bottom: 50px; */
    /* background-color: #fff; */
  }
  
  .header.header-scrolled {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  }

  .header .logo {
    line-height: 0;
  }
  
  .header .logo img {
    max-height: 80px;
    max-width: 250px;
    margin-right: 6px;
  }
  
  .header .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    /* color: #012970; */
    color: #fff;
    /* font-family: "Nunito", sans-serif; */
    font-family: var(--font-family);
    /* margin-top: 3px; */
    /* text-transform: uppercase; */
  }

  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }

  .navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  /* font-family: "Nunito", sans-serif; */
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 700;
  /* color: #013289; */
  color:#fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #4154f1;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #4154f1;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #5969f3;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }

  .hidden{
    display: none;
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #4154f1;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #4154f1;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }















.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
    color: #fff;
    font-size: 30px;
    text-transform:uppercase;
    font-weight: 700;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

/* .gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
} */

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
