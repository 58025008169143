@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}


.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gpt3__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.gpt3__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}
.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}
.footer {
    /* background: #f6f9ff; */
    background: var(--color-footer);
    padding: 0 0 30px 0;
    font-size: 14px;
}
.footer .footer-newsletter {
    color: #fff;
    padding: 50px 0;
    /* background: #f6f9ff; */
    background: var(--color-footer);
    /* border-top: 1px solid #e1ecff; */
}

.footer .footer-newsletter form {
    margin-top: 20px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 8px;
    width: calc(100% - 140px);
  }
  
  .footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 30px;
    margin: 3px;
    background: #4154f1;
    color: #fff;
    transition: 0.3s;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter form input[type=submit]:hover {
    background: #5969f3;
  }

  .footer .footer-top {
    /* background: white url(../img/footer-bg.png) no-repeat right top; */
    background-size: contain;
    /* border-top: 1px solid #e1ecff; */
    /* border-bottom: 1px solid #e1ecff; */
    padding: 60px 0 30px 0;
  }

  @media (max-width: 992px) {
    .footer .footer-top {
      background-position: center bottom;
    }
  }


  .footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  .footer .footer-top .footer-info .logo {
    line-height: 0;
    margin-bottom: 15px;
  }
  
  .footer .footer-top .footer-info .logo img {
    max-height: 90px;
    /* margin-right: 6px; */
  }
  
  .footer .footer-top .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    font-family: "Nunito", sans-serif;
    margin-top: 3px;
  }
  
  .footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: var(--font-family);
    color: #fff;
  }
  
  .footer .footer-top .social-links a {
    font-size: 20px;
    display: inline-block;
    /* color: rgba(1, 41, 112, 0.5); */
    color: #fff;
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .footer-top .social-links a:hover {
    color: #012970;
  }
  
  .footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    /* color: #012970; */
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-top .footer-links ul i {
    padding-right: 2px;
    /* color: #d0d4fc; */
    color: #fff;
    font-size: 12px;
    line-height: 0;
  }
  
  .footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-top .footer-links ul a {
    /* color: #013289; */
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-top .footer-links ul a:hover {
    color: #4154f1;
  }
  
  .footer .footer-top .footer-contact p {
    line-height: 26px;
    color: #fff;
  }
  
  .footer .copyright {
    text-align: center;
    padding-top: 30px;
    /* color: #012970; */
    color: #fff;
  }
  
  .footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    /* color: #012970; */
    color: #fff;
  }
.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.gpt3__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.gpt3__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__possibility-content h4:last-child {
    color: #ff8A71;
}
.gpt3__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}
@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}
.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}


@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.gpt3__header {
    display: flex;
    flex-direction: row;
}

.gpt3__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
    /* margin-top: 100px; */
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    /* flex: 0.6; */
    width: 35%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; */
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}
.header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
    /* padding: 2rem 6rem; */
    /* margin-bottom: 50px; */
    /* background-color: #fff; */
  }
  
  .header.header-scrolled {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  }

  .header .logo {
    line-height: 0;
  }
  
  .header .logo img {
    max-height: 80px;
    max-width: 250px;
    margin-right: 6px;
  }
  
  .header .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    /* color: #012970; */
    color: #fff;
    /* font-family: "Nunito", sans-serif; */
    font-family: var(--font-family);
    /* margin-top: 3px; */
    /* text-transform: uppercase; */
  }

  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }

  .navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  /* font-family: "Nunito", sans-serif; */
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 700;
  /* color: #013289; */
  color:#fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #4154f1;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #4154f1;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #5969f3;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }

  .hidden{
    display: none;
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #4154f1;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #4154f1;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }















.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
    color: #fff;
    font-size: 30px;
    text-transform:uppercase;
    font-weight: 700;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

/* .gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
} */

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.gpt3__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.gpt3__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.gpt3__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.gpt3__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.gpt3__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}


.team {
    /* background: #fff; */
    padding: 60px 0;
}

.team-header h2,
.team-header p{
    color:#fff !important;
}
  
  .team .member {
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
  }

  .team .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .member .member-img:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: url(/static/media/team-shape.150d9866.svg) no-repeat center bottom;
    background-size: contain;
    z-index: 1;
  }


  .team .member .social {
    position: absolute;
    right: -100%;
    top: 30px;
    opacity: 0;
    border-radius: 4px;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    color: rgba(1, 41, 112, 0.5);
    margin: 15px 12px;
    display: block;
    line-height: 0;
    text-align: center;
  }
  
  .team .member .social a:hover {
    color: rgba(1, 41, 112, 0.8);
  }
  
  .team .member .social i {
    font-size: 18px;
  }

  .team .member .member-info {
    padding: 10px 15px 20px 15px;
  }
  
  .team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #012970;
  }
  
  .team .member .member-info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
  }
  
  .team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: #5e5e5e;
  }

  .team .member:hover {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .team .member:hover .social {
    right: 8px;
    opacity: 1;
  }
.services .service-box {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: 0.3s;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .services .service-box .icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
  }

  .services .service-box h3 {
    color: #444444;
    font-weight: 700;
  }
  
  .services .service-box .read-more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 20px;
  }
  
  .services .service-box .read-more i {
    line-height: 0;
    margin-left: 5px;
    font-size: 18px;
  }
  
  .services .service-box.blue {
    border-bottom: 3px solid #2db6fa;
  }
  
  .services .service-box.blue .icon {
    color: #2db6fa;
    background: #dbf3fe;
  }


  .services .service-box.blue .read-more {
    color: #2db6fa;
  }
  
  .services .service-box.blue:hover {
    background: #2db6fa;
  }
  
  .services .service-box.orange {
    border-bottom: 3px solid #f68c09;
  }
  
  .services .service-box.orange .icon {
    color: #f68c09;
    background: #fde3c4;
  }
  
  .services .service-box.orange .read-more {
    color: #f68c09;
  }
  
  .services .service-box.orange:hover {
    background: #f68c09;
  }
  
  .services .service-box.green {
    border-bottom: 3px solid #08da4e;
  }

  .services .service-box.green .icon {
    color: #08da4e;
    background: #cffddf;
  }
  
  .services .service-box.green .read-more {
    color: #08da4e;
  }
  
  .services .service-box.green:hover {
    background: #08da4e;
  }
  
  .services .service-box.red {
    border-bottom: 3px solid #e9222c;
  }
  
  .services .service-box.red .icon {
    color: #e9222c;
    background: #fef7f8;
  }
  
  .services .service-box.red .read-more {
    color: #e9222c;
  }
  
  .services .service-box.red:hover {
    background: #e9222c;
  }

  .services .service-box.purple {
    border-bottom: 3px solid #b50edf;
  }
  
  .services .service-box.purple .icon {
    color: #b50edf;
    background: #f8e4fd;
  }
  
  .services .service-box.purple .read-more {
    color: #b50edf;
  }
  
  .services .service-box.purple:hover {
    background: #b50edf;
  }
  
  .services .service-box.pink {
    border-bottom: 3px solid #f51f9c;
  }
  
  .services .service-box.pink .icon {
    color: #f51f9c;
    background: #feecf7;
  }
  
  .services .service-box.pink .read-more {
    color: #f51f9c;
  }

  .services .service-box.pink:hover {
    background: #f51f9c;
  }
  
  .services .service-box:hover h3,
  .services .service-box:hover p,
  .services .service-box:hover .read-more {
    color: #fff;
  }
  
  .services .service-box:hover .icon {
    background: #fff;
  }
  
.contact .info-box {
    color: #444444;
    /* background: #031047; */
    background: var(--color-footer);
    padding: 30px;
}
.section-contact h2{
    color:#fff;
}
  
  .contact .info-box i {
    font-size: 38px;
    line-height: 0;
    /* color: #4154f1; */
    color: rgb(80, 20, 234);
    /* color: var(--gradient-bar); */
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    /* color: #012970; */
    color: #fff;
    
    /* color: var(--color-text); */
    font-weight: 700;
    margin: 20px 0 10px 0;
  }

  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    color: var(--color-text);
  }
  
  .contact .php-email-form {
    background: #fafbff;
    padding: 30px;
    height: 100%;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }

  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }

  .contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #5969f3;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
*{
    box-sizing: border-box;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

body{
    background: var(--color-bg);
}

a{
    color: unset;
    text-decoration: none;
}

.gradient__bg{

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}


.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .section__padding {
    padding: 4rem 6rem;
  }
  
  .section__margin {
    margin: 4rem 6rem;
  }
  
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }


  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #4154f1;
    text-transform: uppercase;
  }
  .section-contact h2{
    color: #fff;
  }

  .section-services h2{
    color: #fff;
  }
  
  .section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
  }
  
  @media (max-width: 768px) {
    .section-header p {
      font-size: 28px;
      line-height: 32px;
    }
  }
